<template>
  <div class="inside-page-content">
    <div class="paper" style="background-color: #F7F9FE;">
      <div class="notice-content">
        <img :src="detailData.pictureUrl" class="image" />
        <div class="right">
          <div class="yuanqu-name h1">{{ detailData.parkName }}</div>
          <div class="row" style="margin-bottom: 20px;">
            <img src="@/static/images/location.png" class="location" />
            <div class="subtitle">{{ detailData.parkAddress }}</div>
          </div>
          <div class="row">园区描述：{{ detailData.parkDescribe }}</div>
          <div class="row">
            <div>园区画像：</div>
            <div class="tag" v-for="tag in tags" :key="tag">{{ tag }}</div>
          </div>
          <div class="row">园区热线：{{ detailData.parkHotline }}</div>
        </div>
      </div>
      <div class="yuanqugaikuang">
        <div class="gaikuang-title">园区概况</div>
        <div style="font-size: 15px;">{{ detailData.parkSurvey }}</div>
        <div class="gaikuang-title">园区企业数据</div>
        <div class="data-wrapper">
          <div class="data-item-wrapper">
            <div class="data-count">{{ detailData.enterpriseTotal }}</div>
            <div class="data-title">企业总数</div>
          </div>
          <div class="divider-h"></div>
          <div class="data-item-wrapper">
            <div class="data-count">{{ detailData.listedEnterpriseTotal }}</div>
            <div class="data-title">上市企业</div>
          </div>
          <div class="divider-h"></div>
          <div class="data-item-wrapper">
            <div class="data-count">{{ detailData.highTechEnterpriseTotal }}</div>
            <div class="data-title">高新企业</div>
          </div>
          <div class="divider-h"></div>
          <div class="data-item-wrapper">
            <div class="data-count">{{ detailData.aboveScaleEnterpriseTotal }}</div>
            <div class="data-title">规上企业</div>
          </div>
        </div>
        <div class="gaikuang-title">园区资源</div>
        <div style="display: flex;align-items: center;">
          <img :src="detailData.sourceUrl" class="yuanquziyuan" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      notice_id: null,
      detailData: {},
    }
  },
  computed: {
    tags() {
      var parkPortrait = this.detailData?.parkPortrait ?? ''
      var tags1 = parkPortrait.split(',')
      var tags2 = parkPortrait.split('，')
      return tags1.length > tags2.length ? tags1 : tag2;
    },
  },
  created(options) {
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.businessParkDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  // text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}

.notice-content {
  margin-top: 30px;
  height: 300px;
  display: flex;
  align-items: stretch;
  padding: 100px;
  color: #666666;
  background-color: #fff;

  .image {
    flex: 1;
    min-width: 300px;
    height: 100%;
  }

  .right {
    flex: 1;
    min-width: 300px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .yuanqu-name {
      font-weight: 700;
    }

    .row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      flex-wrap: wrap;

      .location {
        width: 17px;
        height: 26px;
        margin-right: 10px;
      }

      .tag {
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        background-color: #a4d9fc;
        text-align: center;
        margin-right: 20px;
        border-radius: 2px;
        margin: 5px;
      }
    }
  }
}

.yuanqugaikuang {
  background-color: #fff;
  padding: 0 40px 40px 40px;
  margin-top: 40px;
  .gaikuang-title {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    margin: 30px 0;
    border-bottom: 1px solid #cccccc;
  }

  .data-wrapper {
    border: solid 1px #cccccc;
    height: 116px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .data-item-wrapper {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .data-count {
        color: #fc6035;
        font-size: 24px;
        margin-bottom: 20px;
      }

      .data-title {
        color: #a19f9e;
        font-size: 14px;
      }
    }

    .divider-h {
      width: 1px;
      height: 100%;
      background-color: #cccccc;
    }
  }

  .yuanquziyuan {
    width: 60%;
    height: auto;
    object-fit: fill;
    margin: 0 auto;
  }
}
</style>
